import React from 'react';

import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Title from '../components/Title/Title';
import heroAboutImg from '../images/aboutNpc/hero.png';
import TCell2MobileImg from '../images/aboutLoqtorzi/T-cells-text-m.inline.svg';
import TCell2Img from '../images/aboutLoqtorzi/T-cells-text.inline.svg';
import Columns from '../components/Columns/Columns';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';
import Footnotes from '../components/Footnotes/Footnotes';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>What is Loqtorzi™ | Patient website</title>
      <meta name='description'content='Find out more about Loqtorzi™, the prescription medicine used to treat adults with NPC that has spread or returned'/>
    </>
  )
}

export default function AboutLoqtorzi() {
  return (
    <Layout>
      <Hero image={ { src: heroAboutImg, alt:'Hero about Loqtorzi' } }>
        <Title>
          <h1>What is LOQTORZI™?</h1>
        </Title>
        <div className={'mt-[1.8rem] tablet:mt-[2.9rem]'}>
          LOQTORZI™ is a prescription medicine used to treat adults with a kind of cancer called NPC. Your healthcare provider will prescribe LOQTORZI™ with chemotherapy* or on its own, depending on your disease and treatment history.
        </div>
      </Hero>
      <GlobalWrapper className={ 'large:pl-indent' }>
        <Title className={'mt-[3.2rem] desktop:mt-[7rem]'}>
          <h2 className='uppercase'>LOQTORZI™ + chemotherapy*</h2>
        </Title>
        <div className='mt-[1.5rem] desktop:mt-[2rem]'>
          <div>LOQTORZI™ may be used in combination with chemotherapy* as your first treatment when your NPC:</div>
          <ul className="bullet-list bullet-list--blue normal-spacing mt-[1rem]">
            <li>Has spread to other parts of your body (metastatic) or</li>
            <li>Has returned (recurrent) in nearby tissues (locally advanced)</li>
          </ul>
          <div className='mt-[1rem]'>
            <strong>LOQTORZI™ may be continued <span className="text-gold-600">chemo free</span> after completing up&nbsp;to&nbsp;6&nbsp;cycles with chemotherapy</strong><sup>†</sup>
          </div>

          <Footnotes className={'mt-[2rem]'} items={[
            {
              bullet: '*',
              text: <span>Chemotherapy is cisplatin and gemcitabine.</span>
            },
            {
              bullet: <sup>†</sup>,
              text:<span>In the clinical study, cisplatin and gemcitabine were given every 3 weeks for the first 6 treatment cycles, followed by LOQTORZI™ given alone.</span>
            }
          ]}/>
        </div>

        <Title className={'mt-[3.6rem] desktop:mt-[5.3rem]'}>
          <h2 className='uppercase'>LOQTORZI™ alone</h2>
        </Title>
        <div className='mt-[1.5rem] desktop:mt-[2rem]'>
          <div>LOQTORZI™ may be used alone to treat your NPC when it:</div>
          <ul className="bullet-list bullet-list--blue normal-spacing mt-[1rem]">
            <li>Has returned (recurrent) and cannot be removed with surgery <strong>or</strong></li>
            <li>Has spread (metastatic), <strong>and</strong></li>
            <li>You received chemotherapy that contains platinum, and it did not work or is no longer working</li>
          </ul>

        <div className={'mt-[4rem]'}>
          It is not known if LOQTORZI™ is safe and effective in children.
        </div>
        </div>
      </GlobalWrapper>
      <GlobalWrapper className={'large:pl-indent'}>
        <Title className={'mt-[3.6rem] desktop:mt-[6.2rem]'}>
          <div className='h1'>How does it work?</div>
          <div className='mt-[1.2rem]'>LOQTORZI™ is a type of medicine called an immunotherapy. Unlike chemotherapy or radiotherapy, immunotherapy works by helping to boost your immune system’s ability to fight cancer.</div>
          <h3 className='mt-[1.4rem]'>
            LOQTORZI™ enhances your immune system's ability to detect and destroy cancer&nbsp;cells
          </h3>
        </Title>
      </GlobalWrapper>

        <GlobalWrapper className={'mobileFull large:pl-indent'}>
          <div className={'box-shadow mt-[1.8rem] pt-[2.6rem] pb-[4rem]'}>
            <Columns className={'tablet:gap-[2rem] large:gap-[5.4rem]'}>
              <div>
              Your immune system makes T cells, a type of immune cell that detects and destroys germs and cells that could make you sick—including cancer cells.
              </div>
              <div>
                <div className='mt-[1.5rem] tablet:mt-0'>
                  Cancer cells can hide from T cells in your immune system, allowing cancer to grow and spread undetected.
                </div>
              </div>
            </Columns>
            <div className='mt-[4.4rem] text-center tablet:mt-[2.8rem]'>
              <TCell2MobileImg className='desktop:hidden mt-[2rem] mx-auto'/>
              <TCell2Img className='hidden desktop:inline-block desktop:mt-0 large:max-w-[62.875rem] large:pt-[3rem] max:max-w-none'/>
            </div>
            <div className='mt-[1rem] tablet:mt-[3.6rem]'>
              <p>LOQTORZI™ is a medicine to treat nasopharyngeal cancer by working with your immune system. LOQTORZI™ can cause your immune system to attack normal organs and tissues in any area of your body and can affect the way they work. These problems can sometimes become severe or life-threatening and can lead to death. You can have more than one of these problems at the same time. These problems may happen anytime during treatment or even after treatment has ended.</p>
            </div>
          </div>

        </GlobalWrapper>
        <GlobalWrapper className={'desktop:pb-[8rem]'}>
          <ColumnsConnections className={'mt-[1.9rem] desktop:mt-[5.6rem]'}
            data={[
              {
                title: 'Stay connected',
                link: '/stay-in-touch/',
                text: 'Get more educational tools and resources to help support you on your treatment journey.',
                className: 'is-active'
              },
              {
                title: 'Explore the results',
                link: '/loqtorzi-clinical-trial-results/',
                text: 'See how LOQTORZI™ may give you the chance for more time without your cancer progressing. '
              },
              {
                title: 'Financial resources',
                link: '/loqtorzi-solutions/',
                text: 'We want to provide you with resources that may help give you access and support for treatment.'
              }
            ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
